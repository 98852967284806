/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
h1,
.cb-layout2 .unit h2 {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.18181818;
}
.banner-fix:after {
  bottom: -48px;
  height: 48px;
  background-position: 100%;
}
@media screen and (min-width: 1389px) {
  .banner-fix:after {
    left: 50%;
    width: 1389px;
    margin-left: -694px;
  }
}
.desk {
  width: 940px;
}
.home {
  margin-top: 72px;
  font-size: 31px;
  font-size: 3.1rem;
  background-size: auto 100%;
}
.navigation {
  float: right;
  width: auto;
}
#expo {
  margin-top: 8.9em;
}
@media screen and (min-width: 1389px) {
  #expo {
    padding: 0;
    height: 432px;
  }
}
.cb-slide-previous,
.cb-slide-next {
  margin-top: -51px;
}
.footer .desk {
  position: relative;
}
.footer:after {
  right: 8.7%;
  width: 237px;
}
.copyright {
  margin-top: 0;
}
div.sub1 {
  clear: both;
  margin-top: 62px;
  margin-right: -0.77em;
  margin-right: -1.17em;
}
div.sub1 .menu {
  padding-left: 0.77em;
  padding-right: 0.77em;
  font-size: 20px;
  line-height: 1.4;
}
div.sub2 .menu {
  padding-right: 1.08705882em;
  padding-left: 1.08705882em;
}
#find {
  top: 19px;
  right: 0;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.south {
  float: right;
  margin: 4px 25.5% 0 0;
  width: 41.4893617%;
}
.south .body {
  margin-top: 1.4em;
}
.south h2 {
  font-size: 18px !important;
  font-size: 1.8rem !important;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */